<template>
    <div class="card">
        <h5>{{ $t('menu.lsportsLeagues') }}</h5>
        <SportEntities :entityType="'league'"></SportEntities>
    </div>
</template>
<script>
import SportEntities from './SportEntities.vue';
export default {
    name: 'lsportsSports',
    components: {
        SportEntities,
    },
    data() {
        return {};
    },
};
</script>
